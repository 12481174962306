.sidebar {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  height: 100%;
}

.sidebar .ant-layout-sider-children {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.sidebar.sidebar-closed {
  background-color: #eff4f8 !important;
}

.sidebar .logo {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8.75px;
  border-bottom: 1px solid #cde0ef;
}

.sidebar .logo img {
  margin-left: 20px;
  /* height: 45px;
  width: 45px; */
}
.logo span {
  margin-left: 10px;
  color: black;
  font-weight: 600;
  font-size: 20px;
  line-height: 1;
}
.sidebar .sidebar-menu {
  background-color: #eff4f8 !important;
  min-width: 18% !important;
  color: #afc8e9 !important;
  font-weight: 600 !important;
  font-family: "Montserrat", sans-serif;
  margin-top: 20px;
  padding-left: 12px;
}

.sidebar .sidebar-menu li.ant-menu-item.ant-menu-item-selected {
  background-color: transparent !important;
  color: black !important;
  font-weight: 600 !important;
}

.sidebar .sidebar-menu .menu-item span {
  font-size: 14px !important;
  /* margin-right: 20px !important; */
}
.ant-menu-dark:not(.ant-menu-horizontal)
  .ant-menu-item:not(.ant-menu-item-selected):hover {
  background-color: transparent;
  color: #afc8e9 !important;
}
.sidebar .sidebar-heading {
  position: absolute !important;
  bottom: 5%;
  padding-left: 18px !important;
  color: black !important;
  font-weight: 600 !important;
}
.icon-div {
  background-color: #7836ff;
  padding: 8px;
  border-radius: 4px;
  margin-left: -5px !important;
  padding-left: -5px !important;
}
/*# sourceMappingURL=styles.css.map */
