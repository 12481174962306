.nav-header-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  align-items: center;
  height: 100%;
  font-family: "Montserrat", sans-serif;
}
.mobile-navbar ul {
  list-style: none;
  display: flex;
  flex-direction: column;
  padding: 10px;
}
.mobile-navbar ul li {
  font-weight: 600;
  font-size: 16px;
  font-family: "Montserrat", sans-serif !important;
  color: white;
  cursor: pointer;
}
.mobile-navbar ul li {
  color: black;
  padding-block: 10px;
  padding-left: 15px;
  margin-block: 5px;
}
.mobile-navbar ul li.selected {
  background: #7836ff;
  cursor: pointer;
  border-radius: 5px;
  font-family: "Montserrat", sans-serif !important;
  font-weight: 600;
  color: #ffffff;
}
.nav-header-mobile {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-inline: 10px;
  color: white;
  cursor: pointer;
}
.drawer-app-name {
  margin-left: 10px;
  color: black;
  font-weight: 600;
  font-size: 20px;
  line-height: 1;
}
.nav-header-container .trigger {
  cursor: pointer;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
}

.nav-header-container .trigger.bg-purple {
  font-size: 20px !important;
  color: white !important;
  padding: 8px;
  border-radius: 50% !important;
  background-color: #eff4f8;
}

.nav-header-container .trigger:hover {
  color: #eff4f8;
}

.nav-header-container .dashboard-title {
  font-weight: 600;
  display: inline-block !important;
  padding-left: 1.2rem;
  height: 37px;
  color: #4d4f5c;
  width: 172px;
  margin-left: 10px;
  text-transform: capitalize;
}

.nav-header-container .site-layout-background-right {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  /* border-left: 1px solid #cde0ef; */
}

.nav-header-container .site-layout-background-right div {
  padding: 0 10px;
  font-size: 1.7rem;
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  align-items: center;
}

.nav-header-container .site-layout-background-right .profile {
  font-size: 16px;
  font-weight: 500;
  font-family: "Poppins";
}

.nav-header-container .site-layout-background-right .headerIcons {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0;
}

.nav-header-container .site-layout-background-right .headerIcons img {
  width: 26px;
  height: 31px;
}

.nav-header-container .site-layout-background-right .profile img {
  width: 50px;
  height: 50px;
  margin-inline: 10px;
  background-color: #afc8e9;
  padding: 8px;
  border-radius: 50px;
}

.dropDownContainer {
  color: #4d4f5c;
  font-family: "Montserrat", sans-serif;
  -webkit-box-shadow: 0 0 40px #0000001f;
  box-shadow: 0 0 40px #0000001f;
  font-family: 17px;
  border-radius: 8px !important;
  width: 250px;
  height: max-content;
}

.dropDownContainer .dropDownList {
  border-radius: 8px !important;
  padding: 10px 0;
}

.dropDownContainer .dropDownList .dropDownItem {
  padding: 0 25px;
  margin: 0;
}

.dropDownContainer .lastChild {
  white-space: normal !important;
  text-overflow: initial !important;
  overflow: initial !important;
  line-height: 2 !important;
  height: 64px !important;
}

.ant-dropdown {
  top: 65px !important;
}
.sidebar-heading-mobile {
  position: fixed;
  bottom: 5%;
  font-weight: 600 !important;
  font-family: "Montserrat", sans-serif;
  display: flex;
  flex-direction: row;
  align-items: center;
}

@media (max-width: 500px) {
  .ant-layout-header {
    padding-inline: 20px;
  }
  .nav-header-container .site-layout-background-right .profile img {
    margin-inline: 0px;
  }
}
