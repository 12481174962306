* {
  /* margin: 0; */
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.ant-table-wrapper .ant-table-thead > tr > th,
.ant-table-wrapper .ant-table-thead > tr > td {
  background-color: #eff4f8 !important;
  font-family: "Montserrat";
  font-weight: 700;
  font-size: 16px;
  color: #202020;
}
.drag-area-div .ant-upload-wrapper .ant-upload-drag {
  background-repeat: no-repeat !important;
  background-size: cover !important;
  background-color: transparent !important;
  height: 340px !important;
  border: none !important;
}
.drag-area-div .ant-upload-wrapper .ant-upload-drag p.ant-upload-text {
  font-family: "Montserrat" !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  color: #7836ff !important;
}
.drag-area-div .ant-upload-wrapper .ant-upload-drag p.ant-upload-hint {
  font-family: "Montserrat" !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  color: white !important;
}
.bg-css {
  background-size: cover !important;
  background-repeat: no-repeat !important ;
}
.ant-upload-wrapper .ant-upload-drag {
  background-color: #dee7f2 !important;
  height: 340px !important;
  border: none !important;
}
.ant-upload-wrapper .ant-upload-drag p.ant-upload-text {
  font-family: "Montserrat" !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  color: #7836ff !important;
}
.ant-upload-wrapper .ant-upload-drag p.ant-upload-hint {
  font-family: "Montserrat" !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  color: black !important;
}
.ant-upload-wrapper .ant-upload-drag .video-upload p.ant-upload-text {
  font-family: "Montserrat" !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  color: white !important;
}
.ant-upload-wrapper .ant-upload-drag .video-upload p.ant-upload-hint {
  font-family: "Montserrat" !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  color: white !important;
}
.video-background {
  position: relative;
  overflow: hidden;
  background-color: black !important;
  opacity: 0.75;
  height: max-content !important;
  width: max-content !important;
  margin: 0 auto;
  padding: 20px !important;
  border: none !important;
  color: white !important;
}

.video-background video {
  position: absolute;
  top: 0%;
  left: 50%;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  transform: translateX(-50%) translateY(-50%);
}

.video-background .ant-upload-wrapper .ant-upload-drag {
  position: relative;
  z-index: 1;
  background-color: transparent !important;
  margin-top: 30px;
  height: 340px !important;
  border: none !important;
}

.ant-popconfirm .ant-popconfirm-inner-content {
  font-family: "Montserrat" !important;
}
.ant-popconfirm-buttons .ant-btn-primary {
  background-color: #f7006b !important;
  font-family: "Montserrat" !important;
}
.ant-menu-dark.ant-menu-inline .ant-menu-item {
  margin-bottom: 15px !important;
}

.ant-popconfirm-buttons .ant-btn-default {
  background-color: #7836ff !important;
  font-family: "Montserrat" !important;
  color: white !important;
}

.ant-switch.ant-switch-checked {
  background-color: #7836ff !important;
}
.modal-lg .ant-modal-content {
  border-radius: 25px !important;
  width: 650px !important;
  background-color: #eff4f8 !important;
}
.modal-lg .ant-modal-body {
  padding: 0px 20px !important;
}
.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover,
.ant-input-affix-wrapper:focus {
  border-color: #d7dbe8 !important;
}
.ant-input:hover,
.ant-input:focus,
.ant-picker:hover,
.ant-picker:focus {
  border-color: #d9d9d9 !important;
}
.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(
    .ant-pagination-size-changer
  ):hover
  .ant-select-selector {
  border-color: #d9d9d9 !important;
}
.ant-radio-wrapper {
  font-family: "Montserrat" !important;
}
.ant-input-disabled {
  background-color: #afc8e9 !important;
  border: 1px solid #d7dbe8 !important;
  color: black !important;
}
tbody tr:nth-child(odd) {
  background-color: white;
  color: #000000;
  font-family: "Montserrat" !important;
  font-weight: 500;
}
tbody tr:nth-child(even) {
  background-color: #eff4f8;
  color: #000000;
  font-family: "Montserrat" !important;
  font-weight: 500;
}
.ant-picker-range,
.ant-select-selector {
  background-color: #afc8e9 !important;
  border-radius: 5px !important;
}
.ant-picker .ant-picker-separator,
.ant-picker .ant-picker-suffix,
.ant-select .ant-select-arrow {
  color: white !important;
}
.ant-picker .ant-picker-input > input,
.ant-select-single.ant-select-lg .ant-select-selector {
  color: white !important;
  font-family: "Montserrat" !important;
  font-weight: 500 !important;
  font-size: 15px !important;
}
.user-location-search .ant-select-selector {
  background-color: #eff4f8 !important;
  border-radius: 5px !important;
  color: #4b4b4b !important;
  border: none !important;
}
.user-location-search .ant-select-single.ant-select-lg .ant-select-selector {
  color: #4b4b4b !important;
  font-family: "Montserrat" !important;
  font-weight: 500 !important;
  font-size: 15px !important;
}
.ant-radio-group {
  color: black !important;
  font-family: "Montserrat" !important;
  font-weight: 700 !important;
  font-size: 14px !important;
}
.listing-filter .ant-picker .ant-select-selector {
  background-color: #eff4f8 !important;
  border-radius: 5px !important;
  border: none !important;
}
.listing-filter.ant-input-affix-wrapper,
.listing-filter .ant-select-selector {
  background-color: #eff4f8 !important;
  border-radius: 5px !important;
  color: #4b4b4b !important;
  border: none !important;
}
.listing-filter .ant-input {
  background-color: #eff4f8 !important;
}
.listing-filter.ant-select-single.ant-select-lg .ant-select-selector,
.listing-filter.ant-select .ant-select-arrow {
  color: #4b4b4b !important;
  font-family: "Poppins" !important;
  font-weight: 500 !important;
  font-size: 13px !important;
}
.add-song-filter.ant-input-affix-wrapper,
.add-song-filter .ant-select-selector {
  background-color: white !important;
  border-radius: 8px !important;
  border: 1px solid #d7dbe8 !important;
}
.add-song-filter.ant-select-single.ant-select-lg .ant-select-selector,
.add-song-filter.ant-select .ant-select-arrow {
  color: #616161 !important;
  font-family: "Montserrat" !important;
  font-weight: 600 !important;
  font-size: 15px !important;
}
.user-location-search .ant-select .ant-select-selection-placeholder,
.listing-filter.ant-select .ant-select-selection-placeholder {
  color: rgba(0, 0, 0, 0.25) !important;
}
.ant-select .ant-select-selection-placeholder {
  color: white !important;
}
.ant-checkbox-wrapper {
  color: black !important;
  font-family: "Montserrat" !important;
  font-weight: 700 !important;
  font-size: 14px !important;
}
.add-song-filter .ant-select-selector {
  height: 50px !important;
}
.add-song-filter .ant-select-selection-item {
  padding: 5px !important;
}
.add-song-textarea.ant-input-lg {
  border-radius: 8px;
}
.ant-drawer .ant-drawer-header{
  display: none !important;
}

@media (max-width: 770px){
  .modal-lg .ant-modal-content {
    width: 100% !important;
  }
}