.message {
  display: flex;
  flex-direction: column;
  background-color: white;
  box-shadow: 0px 5px 20px #1e1e220c;
  border: 1px solid #d7dbe8;
  border-radius: 10px;
  margin-bottom: 20px;
}
.messageHeader {
  background-color: #eff4f8;
  border-radius: 10px 10px 0px 0px;
  display: flex;
  padding: 15px;
  flex-direction: row;
  align-items: center;
}
.imgContainer {
  width: 40px;
  height: 40px;
  background-color: #afc8e9;
  padding: 6px 8px;
  border-radius: 50px;
}
.messageContent {
  height: 120px;
}
