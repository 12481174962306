.ant-layout {
  background-color: white !important;
}
.dashboard-layout {
  color: white;
  overflow: hidden;
  height: 100vh;
}

.dashboard-layout .dashboard-sidebar {
  background-color: #eff4f8 !important;
  font-family: montserrat, sans-serif;
  background: #eff4f8;
  border-right: 1px solid #cde0ef;
}

.dashboard-layout .dashboard-main {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.dashboard-layout .dashboard-main .dashboard-header {
  background: #eff4f8;
  border-left: 1px solid #cde0ef;
  border-bottom: 1px solid #cde0ef;
}

.dashboard-layout .dashboard-main .dashboard-main-content {
  min-height: calc(100vh - 64px);
  overflow: auto;
  padding: 30px 40px;
  background-color: white;
}

.dashboard-layout .dashboard-main .dashboard-main-content.sidebar-closed {
  width: calc(100vw - 80px);
}
@media (max-width: 500px){
  .dashboard-layout .dashboard-main .dashboard-main-content {
    padding: 25px;
  }  
}
