.thumbnailDiv {
  height: 300px;
  width: 100%;
  background-repeat: no-repeat !important;
  background-size: cover !important;
}
.uploadDiv {
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid #d7dbe8;
  border-radius: 8px;
  background-color: white;
  cursor: pointer;
}
.uploadGroupDiv {
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid #d7dbe8;
  background-color: white;
  padding: 7px;
  cursor: pointer;
}
.uploadGroupDiv:first-child {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}
.uploadGroupDiv:last-child {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}
.uploadGroupDiv:hover,
.uploadDiv:hover {
  background-color: #eff4f8;
  border: 1px solid #afc8e9;
}
.imageInput {
  display: none;
}
