.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.center {
  text-align: center;
}
.cursor {
  cursor: pointer;
}
.mb-5 {
  margin-bottom: 5px;
}
.mr-5 {
  margin-right: 5px;
}
.mr-15 {
  margin-right: 15px;
}
.mb-10 {
  margin-bottom: 10px;
}
.mt-10 {
  margin-top: 10px;
}
.mt-20 {
  margin-top: 20px;
}
.my-20 {
  margin-block: 20px;
}
.ml-10 {
  margin-left: 10px;
}
.ml-15 {
  margin-left: 15px;
}
.mb-20 {
  margin-bottom: 20px;
}
.mx-15 {
  margin-inline: 15px;
}
.px-20 {
  padding-inline: 20px;
}
.px-15 {
  padding-inline: 15px;
}
.pt-15 {
  padding-top: 15px;
}
.p-15 {
  padding: 15px;
}
.flex-column {
  display: flex;
  flex-direction: column;
}
.flex-row {
  display: flex;
  flex-direction: row;
}
.flex-row-end {
  display: flex;
  justify-content: flex-end;
}
.flex-row-space-between {
  display: flex;
  justify-content: space-between;
}
.flex-row-align-center {
  display: flex;
  align-items: center;
}
.flex-row-space-center {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.flex-row-space-around-start {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: flex-start;
}
.flex-column-space-center {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}
.table-cell {
  font-family: "Montserrat";
  font-weight: 700;
  font-size: 16px;
  color: #202020;
}
.card {
  background-color: #eff4f8;
  box-shadow: 0px 3px 6px #afc8e94d;
  border-radius: 2px;
  height: 100%;
  padding: 20px;
}
.stats-card {
  background-color: white;
  border-radius: 10px;
  height: 100%;
  padding: 15px;
}
.wrapper {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 16px 50px #4e7d961a;
  border-radius: 30px;
  padding-block: 15px;
}
.h-100 {
  height: 100%;
}
.w-100 {
  width: 100%;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
